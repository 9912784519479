import React from 'react';
import loading from './loading.gif';

const Loading = () => {
  return (
    <div className='d-center text-center'>
      <img
        src={loading}
        alt='loading'
        style={{ width: '20px', height: '20px' }}
      />
    </div>
  );
};

export default Loading;
