import React from 'react';
import { useFetch } from '../customHooks';
import PageHeader from '../component/PageHeader';
import data from '../models/about';
import Loading from '../component/loading';

const About = () => {
  const { isLoading, results } = useFetch(data);

  const { desc, mission, image, providers } = results;
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <PageHeader title='About' metaContent='This is the new task'></PageHeader>
      <section className='about_top'>
        <div className='container'>
          <div className='about_top_wrap'>
            <div className='about_top_info'>
              <h2>
                We Offer Fast and Reliable Healthcare Solutions to Our Clients.
              </h2>
              <p>{desc}</p>
            </div>
            <div className='about_top_info_img'>
              <img
                src={image[1]}
                alt='coast to coast healthcare caregivers'
                className='image-display-1'
              />
            </div>
          </div>
        </div>
      </section>
      <section
        className='about ct-3'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/staff_1.jpg)`,
          backgroundSize: 'cover',
        }}
      >
        <div className='container'>
          <h2 className='text-white title-1'>Our Mission</h2>
          <p>{mission}</p>
        </div>
      </section>
      <section className='team'>
        <div className='container'>
          <div className='section-header text-center'>
            <h2 className='title-1'>Our Team</h2>
            <p>{providers.title}</p>
          </div>
          {/* <div className='col-10 d-center'>
            <div className='row'>
              {providers.lists.map((staff, i) => {
                const { name, position } = staff;
                return (
                  <div className='col-6' key={i}>
                    <div className='teams text-center shadow'>
                      <h3>{name}</h3>
                      <p>{position}</p>
                    </div>
                  </div>
                );
              })}
            </div> 
          </div>
          */}
        </div>
      </section>
    </>
  );
};

export default About;
