import React from 'react';
import { Link } from 'react-router-dom';
import { useFetch } from '../customHooks';
import SlideShow from '../component/slideshow/SlideShow';
import Testimonial from '../component/testimonial/Testimonial';
import data from '../models/homeData';
import PageMeta from '../component/PageMeta';
import Loading from '../component/loading';
import { FaCircle } from 'react-icons/fa';

const Home = () => {
  const { isLoading, results } = useFetch(data);

  const {
    title,
    tagline,
    description,
    lists,
    metaData,
    content,
    image,
  } = results;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageMeta title={metaData.title} desc={metaData} />
      <SlideShow />

      <section className='ct-0'>
        <div className='container'>
          <div className='ct-0-content'>
            <div className='entry'>
              <h2 className='text-red'>{title}</h2>
              <h1 className='title-1 '>{tagline}</h1>
              <p>{description}</p>
              <ul className='ct-list'>
                {lists.map((list, i) => (
                  <li key={i}>
                    <FaCircle />
                    <span> {list}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className='img-container'>
              <div className='img-c-1'>
                <img
                  src={image[0]}
                  alt='senior white woman with registered nurse.'
                  width='600px'
                  height='400px'
                />
              </div>
              <div className='img-c-2'>
                <img
                  src={image[1]}
                  alt='Homemaker in the kitchen with a senior woman'
                  width='600px'
                  height='400px'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='ct-2 bg-light'>
        <div className='container'>
          <div className='ct-2-row'>
            <div className='ct-2-image'>
              <img
                src={content[0].image}
                alt={content[0].title}
                className='image-display-1 shadow'
                width='600px'
                height='400px'
              />
            </div>

            <div className='entry'>
              <p className='text-red p-0'>What we do?</p>
              <div className='entry-content'>
                <h2>{content[0].title}</h2>
                <p>{content[0].desc_1}</p>
                <p>{content[0].desc_2}</p>
              </div>
              <Link
                to='/services'
                className='btn btn-red btn-lg mb-4 shadow'
                aria-label='more about our services'
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section
        className='ct-3'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/staff_1.jpg)`,
          backgroundSize: 'cover',
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-9 d-center entry text-center'>
              <div className='entry'>
                <div className='entry-content'>
                  <h2 className='title-1 text-white'>{content[1].title}</h2>
                  <p>{content[1].desc}</p>
                </div>
                <Link to='/contact' className='btn btn-red btn-lg '>
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonial />
    </>
  );
};

export default Home;
