import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Navbar from './component/navbar/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Providers from './pages/Providers';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Service from './pages/Service';
import HipaaCompliant from './pages/Hipaa';
import Footer from './component/footer/Footer';
import ScrollToTop from './component/ScrollToTop';
import Faq from './pages/Faq';
import ThankYou from './pages/ThankYou';
import PageNotFound from './pages/PageNotFound';

const App = () => {
  return (
    <>
      <ScrollToTop>
        <Navbar />
        <main>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/about' component={About} />
            <Route exact path='/care-providers' component={Providers} />
            <Route exact path='/services' component={Services} />
            <Route exact path='/services/:slug' component={Service} />
            <Route exact path='/hipaa-compliant' component={HipaaCompliant} />
            <Route exact path='/faq' component={Faq} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/thank-you' component={ThankYou} />
            <Route exact path='*' component={PageNotFound} />
          </Switch>
        </main>
        <Footer />
      </ScrollToTop>
    </>
  );
};

export default App;
