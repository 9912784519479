import './Testimonial.css';
import React, { useState, useEffect } from 'react';
import { FaStar, RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/all';
import data from '../../models/testimonial';

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setTestimonials(data);
  }, []);

  const displayRatings = (num) => {
    return [...Array(num)].map((star, i) => <FaStar key={i} />);
  };

  const prev = () => {
    setIndex((currentIndex) => {
      let index = currentIndex - 1;
      if (index < 0) {
        index = testimonials.length - 1;
      }
      return index;
    });
  };
  const next = () => {
    setIndex((currentIndex) => {
      let index = currentIndex + 1;
      if (index > testimonials.length - 1) {
        index = 0;
      }
      return index;
    });
  };

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex((currentIndex) => {
        let index = currentIndex + 1;
        if (index > testimonials.length - 1) {
          index = 0;
        }
        return index;
      });
    }, 5000);

    return () => {
      clearInterval(slider);
    };
  }, [index, testimonials]);

  const renderTestimonials = (
    <div className='testimonial-wrap'>
      {testimonials &&
        testimonials.map((testimonial, i) => {
          const { id, image, name, job_title, text, ratings } = testimonial;
          let position = 'next-slide';
          if (i === index) position = 'active-slide';
          if (i === index - 1 || (index === 0 && i === testimonials.length - 1))
            position = 'prev-slide';

          return (
            <div className={`${position} testimonial-card`} key={id}>
              <img src={image} alt={name} width='90px' height='90px' />
              <h2>{name}</h2>
              <p>{displayRatings(ratings)}</p>
              <p>{job_title}</p>
              <p>{text}</p>
            </div>
          );
        })}
      <div className='actions'>
        <button onClick={prev} aria-label='previous'>
          <RiArrowLeftSLine className='arrow' />
        </button>
        <button onClick={next} aria-label='next'>
          <RiArrowRightSLine className='arrow' />
        </button>
      </div>
    </div>
  );

  return (
    <section className='testimonials'>
      <div className='container'>
        <div className='section-header'>
          <p className='text-blue'>Testimonials </p>
          <h2>What People are saying About Us!</h2>

          <div className='underline'></div>
        </div>
        {renderTestimonials}
      </div>
    </section>
  );
};

export default Testimonial;
