import React from 'react';
import { useFetch } from '../customHooks';
import PageHeader from '../component/PageHeader';
import data from '../models/providers';
import { FaCircle } from 'react-icons/fa';

const Services = () => {
  const { isLoading, results } = useFetch(data);

  const { title, description, caregivers_provided, managers } = results;

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <>
      <PageHeader
        title='Our Care Providers'
        metaContent='We at Coast to Coast provides and assistance in day-to-day living to help clients stay comfortable at home'
      />

      <section className='ct-0'>
        <div className='container'>
          <div className='row'>
            <div className='col-8'>
              <div className='entry'>
                <h1 className='title-1'>{title}</h1>
                <p>{description}</p>

                <div className='caregivers'>
                  <h2>{caregivers_provided?.title}</h2>
                  <ul className='ct-list'>
                    {caregivers_provided?.list?.map((caregiver, i) => (
                      <li key={i}>
                        <FaCircle />
                        <span>{caregiver}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='managers'>
                  {managers.map((manager, i) => {
                    const { name, position } = manager;
                    return (
                      <div className='manager' key={i}>
                        <h4>{name}</h4>
                        <p>{position}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
