import React from 'react';
import { Link } from 'react-router-dom';

const ThankYou = () => {
  return (
    <div className='thank-you'>
      <div className='container'>
        <div className='text-center'>
          <h1>Thank you for getting in touch! </h1>
          <div className='thank-you-message'>
            <p>
              We have received your message and we will reply as soon as
              possible. If in the event your inquiry is urgent, please use the
              telephone number listed below to talk to one of our staff members.
            </p>
            <a href='tel:3059999295'>(305) 999 - 9295</a>
          </div>

          <Link to='/' className='btn btn-red'>
            Back Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
