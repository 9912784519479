import React, { useState } from 'react';
import {} from 'react-icons';
import { useFetch } from '../customHooks';
import PageHeader from '../component/PageHeader';
import data from '../models/faq';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';

const Questions = ({ title, text }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className='accordion' onClick={() => setShowInfo(!showInfo)}>
      <div className='accordion-header'>
        <h2>{title}</h2>
        {showInfo ? <FaMinusCircle /> : <FaPlusCircle />}
      </div>
      <p className={showInfo ? 'show' : 'hide'}>{text}</p>
    </div>
  );
};

const Faq = () => {
  const { isLoading, results } = useFetch(data);

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  const renderList = results?.lists?.map((list, i) => (
    <Questions key={i} {...list} />
  ));

  return (
    <>
      <PageHeader title='Frequently Asked Questions' />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-8 d-center'>
              <p>
                Welcome to Coast to Coast FAQ. Here you will find the most
                frequently asked questions are answers. If you have more
                questions that are not displayed here, feel free to reach out to
                us. Contact us by calling our main office located in Miami-Dade
                at 305-999-9295.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='p-0'>
        <div className='container'>
          <div className='row'>
            <div className='col-8 d-center'>{renderList}</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
