const testimonial = [
  {
    id: 1,
    text:
      'I am extremely satisfied with the service that Coast to Coast provide.  My life has changed in many ways since the aide they sent me came into my life. She is God’s gift to me and I am very happy with her. The aide is very attentive to me and make sure my daily needs are met. The aide has become apart of my family and I have Coast to Coast to thank for that. They are the best around!',
    name: 'A.K',
    location: 'Miami',
    image: '../images/default.png',
    ratings: 5,
  },
  {
    id: 2,
    text:
      'I have been with Coast to Coast Healthcare Solution for over seven years. They are the first healthcare family I used following my spinal surgery. The caregivers are the best in the whole wide world. They are very compassionate, dependable, and there to assist you with your daily needs.  I have been fortunately blessed to have such a great aide. I thank God for the many blessings from Coast to Coast and their wonderful caregivers.',
    name: 'Annie',
    location: 'Miami',
    image: '../images/default.png',
    ratings: 5,
  },
  {
    id: 3,
    text:
      'I’ve been apart of the Coast to Coast family for many years. I am very dependent on Coast to Coast for their reliable services that they provide and for having such wonderful aides to assist me with my daily needs. Coast to Coast is the best and I highly recommend their services to anyone that is in need of superior care. They will not let you down!',
    name: 'Evelyn Q.',
    location: 'Broward',
    image: '../images/default.png',
    ratings: 5,
  },
];

export default testimonial;
