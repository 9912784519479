const faq = {
  title: 'A few FAQ from our Clients',
  lists: [
    {
      title: 'What is Home Health Care?',
      text:
        'Recuperating from an illness or recent hospitalization requires continued care for a full recovery. Home Care services provide skilled care or custodial care in the comfort of your home. Whether it’s a need for a Registered Nurse or a Home Health Aide, we will provide that care 24 hours, 7 days per week.',
    },
    {
      title: 'Why Should I choose Home Health Care?',
      text:
        'Personalized Care in the comfort of your own home. Service with Patience and Compassion from our professional staff meeting the highest standards and much, much more.',
    },
    {
      title: 'What Options are available?',
      text:
        'You can expect, individualize care from Registered Nurses, Licensed Practical Nurses, Certified Nurse’ Assistants, Home Health Aides, Companion Sitters, and Homemakers.',
    },
    {
      title: 'How many hours of care can I receive?',
      text:
        'Coast to Coast will tailor your requirement of time from 4 hours to 24 hours. We provide care 24 hours seven days per week.',
    },
    {
      title: 'What are ADL’s ?',
      text:
        'ADLs are Activities of Daily Living, including bathing, dressing, toileting, feeding, and mobility. Clients often need help with ADL’s.',
    },
    {
      title: 'Where Do I Start?',
      text:
        'Quality home health care assistance demands caring hands with a professional touch. Coast to Coast brings that quality to you. You may call us or send an e-mail listed on our site.',
    },
    {
      title: 'How do I choose a Health Care Provider?',
      text:
        'With more than 30 years of combined healthcare experience, Coast to Coast staff of professionals offer superior care and personalized service.',
    },
    {
      title: 'What Makes Coast to Coast Different?',
      text:
        'Service is given with patience and compassion in a trusting professional relationship to ensure that all your needs are met.',
    },
    {
      title: 'Who pays for Home health care?',
      text:
        'Insurance Companies, Private Payments, Worker’s Compensation, Automobile Insurance, Medicaid Waivers, Major Credit cards.',
    },
  ],
};
export default faq;
