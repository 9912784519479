const hippa = {
  notice:
    'This notice describe how medical information about you maybe  used. It also describe how it maybe disclosed and how you can get access to this information.',
  medical: {
    text:
      'Medical information, including health history, is collected from the client upon initiation and subsequent visits, and is then stored in the clients file.',
    list: [
      'Medical information will be used in the assessment of the client’s condition and the need for health care or referral purposes.',
      'Some of the medical information will be transferred to a computer program for the purposes of retrieval, storage, billing and payment purposes.',
      'Medical information may be disclosed to health and disability insurers for the purpose of payment or reimbursement of services.',
      'The medical information contained in the medical record will be stored by Coast To Coast for a period of no less than six (6) years (or longer if state law mandates a longer period of record keeping).',
    ],
  },
  health_care_providers: {
    text:
      'Health care providers, for the purpose of referral, consultation or coordination of health care includes:',
    list: [
      'Health care insurers',
      'Disability insurers',
      'Business Associates',
      'Persons responsible for client’s health care, such as a parent or nurse',

      'Billing organizations',
      'Collection agencies',
    ],
  },
  law_enforcement: {
    text: 'Law enforcement officials or agencies includes:',
    list: [
      'Correctional institution',
      'Public Health authorities',
      'Research institutes',
      'Family members',
      'Workers’ compensation insurers or state agency, if applicable ',
      'Examples of health care information use and disclosure includes: ',
      'For the purpose of diagnosis, assessment, referral, and/or treatment ',
      'For the purpose of payment by a third party, such as a health insurer',
      'For the purpose of day to day health care operations',
      'Appointment reminder notices or messages',
    ],
  },
  disclosures: {
    text:
      'The likely uses and disclosures of health information routinely performed by Coast to Coast Healthcare',
    list: [
      'When requested by client or medical professionals to make verbal “observations” of client to better inform the medical professionals of our client’s condition and/or current state.',
      'As a courtesy to our clients, we will submit an itemized billing statement to your insurance carrier for the purpose of payment to Coast To Coast for services rendered. If you pay for your health care services personally, we will provide an  itemized billing to your insurance carrier for the purpose of reimbursement to you.',
    ],
  },
  clients_rights: {
    text: 'The client’s rights',
    list: [
      'The right to inspect and copy his / her protected health information',
      'The client’s right to place restrictions on certain uses and disclosures of his/her health information. Coast To Coast is not required to honor the requested restriction.',
      'The right to amend his/her protected health information.',
      'The right to receive confidential communication of protected health information by an alternative method other than the stated means of communication.',
      'The right to a copy of the client’s Notice of Privacy Practices at any time.',
      ' The right to an accounting of all uses and disclosures of  his/her protected health information.',
      'Third party uses and disclosures other than for purposes of treatment, payment or health care operations, will be made only with the client’s written authorization, and the client may revoke such authorization as provided by law.',
      'Coast To Coast will make every effort possible to protect the privacy and confidentiality of all health information of its clients against inappropriate or unauthorized use and disclosure, as required by law.',
      ' Coast To Coast promises to inform the client on future changes and revisions to the Notice of Privacy Practices, within 60 days of a material revision to the notice requirements.',
      'The client has a right to file a complaint, as well as the process of filing a complaint, against Coast To Coast for the inappropriate or illegal way in which they handled the client’s health information.',
      'Coast To Coast may not use or disclose protected health  information of its clients in a manner inconsistent with the Notice of Privacy Practices.',
    ],
  },
};

export default hippa;
