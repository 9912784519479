const data = {
  title: 'Welcome to Coast to Coast Healthcare Solutions',
  tagline: 'Personalized Senior Care in the Comfort of your Home.',
  description:
    'For over 18 years Coast To Coast Solutions have been making a difference in the lives of seniors and other clients in need of health care or support. Our clients have entrusted our team of caregivers to provide the best care solutions for their senior loved ones by helping them age comfortably and gracefully at home.',
  lists: [
    'We strives to make paying for Florida home healthcare easy and affordable.',
    'Our professional service team consists of CNA’s, LPN’s, RN’s, Home Health Aides, and Companion Sitters.',
    'We work with our clients and their families to create the perfect in-home healthcare package.',
  ],
  image: ['../images/banner5.jpg', '../images/cargiver-cooking.jpg'],
  metaData: {
    title: 'Best Nurse Registry for senior healthcare',
    slug: 'seo.jpg',
    content: 'Programming blog and tutorials on React, Node, Vue',
  },
  content: [
    {
      title: 'We understand the importance of time.',
      desc_1:
        'As part of our Senior Healthcare service, our service personnel helps you or your loved ones in personal care. We assisted with bathing, dressing, transferring, toileting,  feeding, and walking..',
      desc_2:
        'We also provide other services like homemaker, where we perform the following tasks cooking, housekeeping, grocery shopping, cleaning, and doing the laundry.',
      image: '../images/respite-care.jpg',
    },
    {
      title: ' Raising The Standard Of Senior Care.',
      desc:
        ' Our professionals are well-trained in using emergency monitoring systems and also they coordinate with the family physician as well as other healthcare professionals. They also regularly communicate with other members of the family and update them the health condition and other aspects pertaining to the elderly clients.',
      image: '../images/staff_3.jpg',
    },
  ],
};

export default data;
