import './Footer.css';
import React from 'react';
import { useFetch } from '../../customHooks';
import { Link } from 'react-router-dom';
import data from '../../models/options';
import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  const { results } = useFetch(data);
  const { licenseNumber, tollFreeNumber } = results;

  console.log(results);

  const FooterLink = ({ label, url }) => {
    return (
      <li className='nav-item'>
        <Link to={url} className='nav-link'>
          {label}
        </Link>
      </li>
    );
  };

  const SocialMedia = ({ link, title, Icon, iconClass }) => {
    return (
      <a href={link} target='_blank' title={title} rel='noreferrer'>
        {Icon && <Icon className={`footer-social-icon ${iconClass}`} />}
      </a>
    );
  };

  return (
    <footer>
      <div className='footer-widget'>
        <div className='container'>
          <div className='footer_section_row'>
            <div className=' footer_section'>
              <h2>About Us</h2>
              <div className='underline'></div>
              <p>
                Coast to Coast Healthcare Solutions offers customized senior
                healthcare and home health care services in South Florida,
                Miami-Dade, Broward, and Palm Beach county.
              </p>
              <div className='widget'>
                <div className='license-number'>{licenseNumber} </div>
                <div className='toll-free'>
                  Toll Free Number: {tollFreeNumber}
                </div>
              </div>
            </div>

            <div className=' footer_section'>
              <div className='widget'>
                <h2>Quick Links</h2>
                <div className='underline'></div>
                <div className='footer-nav-wrap'>
                  <ul className='footer-nav'>
                    <FooterLink label='Home' url='/' />
                    <FooterLink label='About' url='/about' />
                    <FooterLink label='FAQ' url='/' />
                    <FooterLink label='Privacy Policy' url='/privacy-policy' />
                    <FooterLink label='Terms' url='/terms' />
                    <FooterLink label='Contact' url='/contact' />
                  </ul>
                  <ul className='footer-nav'>
                    <FooterLink label='Care Providers' url='/care-providers' />
                    <FooterLink label='Homemaker' url='/services/homemaker' />
                    <FooterLink
                      label='Personal Care'
                      url='/services/personal-care'
                    />
                    <FooterLink
                      label='Respite Care'
                      url='/services/respite-care'
                    />
                    <FooterLink
                      label='Companion Sitters'
                      url='/services/companion'
                    />
                    <FooterLink
                      label='Skilled Care Nursing'
                      url='/services/skilled-care-nursing'
                    />
                  </ul>
                </div>
              </div>
            </div>
            <div className=' footer_section'>
              <div className='widget'>
                <h2>Social Media</h2>
                <div className='underline'></div>
                <div className='d-flex'>
                  <SocialMedia
                    link='https://www.facebook.com/CTChealthcare/'
                    title='Like us on Facebook'
                    Icon={FaFacebook}
                    iconClass='fb'
                  />
                  <SocialMedia
                    link='https://twitter.com/ctcnursing'
                    title='Follow us on Twitter'
                    Icon={FaTwitter}
                    iconClass='tw'
                  />
                  <SocialMedia
                    link='https://www.linkedin.com/company/coast-to-coast-healthcare-solutions-inc'
                    title='Follow us on Linkedin'
                    Icon={FaLinkedin}
                    iconClass='tw'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-bottom '>
        <div className='container footer-border'>
          <div className='footer-bottom-content'>
            <div className='copyright'>
              &copy; {new Date().getFullYear()} Coast to Coast Healthcare
              Solutions, All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
