import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Loading from '../component/loading';
const ContactForm = () => {
  // const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState('');
  const [error, setError] = useState('');
  // const [success, setSuccess] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const { name, email, message } = formData;
  const history = useHistory();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setError('');
    // setSuccess('');
  };

  const verifyRecaptcha = async (value) => {
    await axios.post(
      '/api/recaptcha/',
      { value },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    // setVerified(res.data.message);
  };

  const sendData = async () => {
    const data = { name, email, message };
    const headers = { 'Content-Type': 'application/json' };
    const res = await axios.post('/api/', data, { headers });

    if (res.data.error) {
      setFormData({
        ...formData,
      });
      setError(res.data.error);
      // setSuccess('');
      return false;
    }

    setFormData({
      ...formData,
      name: '',
      email: '',
      message: '',
    });
    setError('');
    // setSuccess(res.data.message);
    history.push('/thank-you');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      return setError('Please enter a valid name');
    }
    if (!email) {
      return setError('Please enter a valid email');
    }
    if (!message) {
      return setError('Please enter a valid message');
    }
    if (!name || !email || !message) {
      return setError('All fields are required');
    }
    setLoading(true);
    sendData();
    // setVerified(false);
    setLoading(false);
  };

  const contactForm = (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-6'>
          <div className='form-group'>
            <label>Name</label>
            <input
              type='text'
              name='name'
              value={name}
              autoFocus
              onChange={handleChange}
              className='form-input '
              placeholder='Enter your Name'
            />
          </div>
        </div>
        <div className='col-6'>
          <div className='form-group'>
            <label>Email</label>
            <input
              type='email'
              name='email'
              value={email}
              onChange={handleChange}
              className='form-input '
              placeholder='Enter your Email'
            />
          </div>
        </div>
      </div>

      <div className='form-group'>
        <label>Message</label>
        <textarea
          name='message'
          value={message}
          cols='30'
          rows='10'
          onChange={handleChange}
          className='form-input '
          placeholder='Enter your Message'
        />
      </div>
      <div className='form-group'>
        <ReCAPTCHA
          sitekey='6LdJxB8aAAAAAMHajpr3lRDdORtNXPz_zr06YqO0'
          onChange={verifyRecaptcha}
        />
      </div>
      <div className='form-group'>
        <button
          className='btn btn-red btn-x-lg'
          type='submit'
          aria-label='submit contact form'
        >
          {loading ? (
            <>
              <Loading /> ...sending
            </>
          ) : (
            <>Send</>
          )}
        </button>
      </div>
    </form>
  );
  return (
    <>
      <p className='error'>{error && error}</p>

      {contactForm}
    </>
  );
};

export default ContactForm;
