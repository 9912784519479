import React from 'react';
import { useFetch } from '../customHooks';
import ContactForm from '../component/ContactForm';
import { FaFax, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';

import PageHeader from '../component/PageHeader';
import data from '../models/contact';

const Contact = () => {
  const { isLoading, results } = useFetch(data);

  const { offices } = results;

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  const renderLocations =
    offices &&
    offices?.map((office, i) => {
      const { name, street, suite, city, state, zip, tel, fax } = office;
      return (
        <div className='office' key={i}>
          <h3>{name}:</h3>

          <address>
            <FaMapMarkerAlt className='marker' />
            <a
              href={`http://maps.google.com/?q=${street} ${
                suite && suite
              } ${city} ${state} ${zip}`}
              target='_blank'
              rel='noreferrer'
            >
              {street}
              {suite && ', ' + suite} {city}, {state} {zip}
            </a>
          </address>

          <p>
            <FaPhoneAlt />
            <strong>Phone: </strong>

            <a href={`tel:${tel}`} rel='noreferrer'>
              {tel}
            </a>
          </p>
          <p>
            <FaFax />
            <strong>Fax: </strong>
            {fax}
          </p>
        </div>
      );
    });

  return (
    <>
      <PageHeader
        title='Contact'
        content={`Contact ${process.env.REACT_APP_SITE_NAME} anytime`}
      />
      <section className='contact'>
        <div className='container'>
          <div className='contact_content'>
            <div className='contact_left'>
              <h2>Get in Touch with Us</h2>

              <ContactForm />
            </div>

            <div className='contact_right'>
              <div className='offices'>{renderLocations}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
