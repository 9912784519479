const contact = {
  email_address: '',
  website: 'www.ctchealthcare.com',
  offices: [
    {
      name: 'Miami-Dade Office',
      street: '561 NW 183rd Street',
      suite: '',
      city: 'Miami Gardens',
      state: 'FL',
      zip: ' 33169',
      tel: '305 999-9295',
      fax: '305 999-9259',
    },
    {
      name: 'Broward Office',
      street: '4700 N State Rd 7',
      suite: 'Suite A-101',
      city: 'Lauderdale Lakes',
      state: 'FL',
      zip: ' 33319',
      tel: '954 667-1125',
      fax: '954 667-1130',
    },

    {
      name: 'Palm Beach Office',
      street: '900 NW 13 Street',
      suite: 'Suite #106',
      city: 'Boca Raton',
      state: 'FL',
      zip: ' 33486',
      tel: '561 931-3580',
      fax: '305 999-9259',
    },
  ],
};

export default contact;
