import './Navbar.css';
import React, { useState, useEffect } from 'react';
import { useFetch } from '../../customHooks';
import { Link, useLocation } from 'react-router-dom';
import {
  FaBars,
  FaClock,
  FaEnvelope,
  FaPhoneAlt,
  FaTimes,
} from 'react-icons/fa';
import navLinks from '../../models/navbar';
import data from '../../models/options';

const Navbar = () => {
  const [links, setLinks] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const location = useLocation();
  const { results } = useFetch(data);
  const { email, tollFreeNumber } = results;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setLinks(navLinks);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    window.scrollY > 150 ? setIsSticky(true) : setIsSticky(false);
  };

  const renderNavLinks = links.map((link, i) => {
    const { label, url } = link;

    return (
      <li
        className={`nav-link ${location.pathname === url ? 'active' : ''}`}
        key={i}
      >
        <Link to={url} className={`nav-item `}>
          {label}
        </Link>
      </li>
    );
  });

  return (
    <header className={`header ${isOpen ? 'is-mobile' : ''}`}>
      <div className='header-top'>
        <div className='container'>
          <div className='header-top-content'>
            <div className='header-top-left'>
              <FaClock aria-label='Coast to Coast Healthcare hours' />
              We are on call 24 hours a day
            </div>
            <div className='header-top-right'>
              <div className='header-top-tel'>
                <FaPhoneAlt aria-label='phone number' />{' '}
                <a href='tel:3059999295'>{tollFreeNumber}</a>
              </div>
              <div className='header-top-email'>
                <FaEnvelope />
                <a href='mailto:info@ctchealthcare.com' rel='noreferrer'>
                  {email}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className={`navbar ${isSticky ? 'nav-sticky' : ''} `}>
        <div className='container'>
          <div className='nav_wrap'>
            <Link to='/' className='logo'>
              <img
                src='../../images/ctc_logo.png'
                alt='logo'
                width='180'
                height='67'
              />
            </Link>

            <button className='toggle-btn' onClick={toggleMenu}>
              {isOpen ? (
                <FaTimes aria-label='close menu' />
              ) : (
                <FaBars aria-label='open menu' />
              )}
            </button>

            <ul className='nav'>{renderNavLinks}</ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
