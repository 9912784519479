const navLinks = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'About Us',
    url: '/about',
  },
  {
    label: 'Care Providers',
    url: '/care-providers',
  },
  {
    label: 'Services',
    url: '/services',
  },
  {
    label: 'HIPAA Compliant',
    url: '/hipaa-compliant',
  },

  {
    label: 'FAQ',
    url: '/faq',
  },
  {
    label: 'Contact',
    url: '/contact',
  },
];

export default navLinks;
