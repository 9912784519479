import React from 'react';
import PageMeta from './PageMeta';

const PageHeader = ({ title, content }) => {
  return (
    <>
      <PageMeta title={title} content={content} />
      <div className='page-header'>
        <div
          className='page-inner'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/staff_2.jpg)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
          }}
        >
          <div className='container page-header-wrap'>
            <div className='page-header-content'>
              <div className='page-header-left'>
                <h2>{title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
