import React from 'react';

import { useFetch } from '../customHooks';
import PageHeader from '../component/PageHeader';
import data from '../models/hippa';
import { FaCircle } from 'react-icons/fa';

const HipaaCompliant = () => {
  const { isLoading, results } = useFetch(data);

  const {
    notice,
    medical,
    health_care_providers,
    disclosures,
    clients_rights,
    law_enforcement,
  } = results;

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  const renderList = (list) => (
    <ul className='ct-list'>
      {list.map((list, i) => (
        <li key={i}>
          <FaCircle />
          <span>{list}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <PageHeader
        title='HIPAA Compliant'
        image='/images/banner2.jpg'
        alt='caregiver with seniors'
      />

      <section className='hippa'>
        <div className='container'>
          <div className='row'>
            <div className='col-8 d-center'>
              <div className='entry'>
                <p className='notice'>{notice}</p>
                <p> {medical?.text}</p>

                {renderList(medical?.list)}
              </div>
              <div className='enrty mt-5'>
                <h2 className='title-2'>{health_care_providers?.text}</h2>
                {renderList(health_care_providers?.list)}
              </div>

              <div className='enrty mt-5'>
                <h2 className='title-2'>{law_enforcement?.text}</h2>
                {renderList(law_enforcement?.list)}
              </div>

              <div className='enrty mt-5'>
                <h2 className='title-2'>{disclosures?.text}</h2>
                {renderList(disclosures?.list)}
              </div>

              <div className='enrty mt-5'>
                <h2 className='title-2'>{clients_rights?.text}</h2>
                {renderList(clients_rights?.list)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HipaaCompliant;
