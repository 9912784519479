import React from 'react';
import { Helmet } from 'react-helmet-async';

const PageMeta = ({ title, content, route }) => {
  return (
    <Helmet>
      <title>{`${title} | ${process.env.REACT_APP_SITE_NAME}`}</title>
      <link rel='canonical' href={process.env.REACT_APP_DOMAIN} />

      <meta
        name='description'
        content={
          content ||
          'Coast to Coast Healthcare Solutions offers customized senior healthcare as well as home health care services in South Florida, Miami-Dade and Broward.'
        }
      />
      {/*  <link rel='canonical' href={`${process.env.REACT_APP_DOMAIN}${router.asPath}`} /> */}
      {
        // facebook open graph
      }
      <meta
        property='og:title'
        content={`${title} | ${process.env.REACT_APP_SITE_NAME}`}
      />
      <meta property='og:description' content={content} />
      <meta property='og:type' content='website ' />
      <meta
        property='og:url'
        content={`${process.env.REACT_APP_DOMAIN}${route}`}
      />
      <meta property='og:site_name' content={process.env.REACT_APP_SITE_NAME} />

      {/*  <meta
        property='og:image'
        content={`${process.env.REACT_APP_DOMAIN}/post/image/${page.slug}`}
      /> 
      <meta
        property='og:image:secure_url'
        content={`${process.env.REACT_APP_DOMAIN}/post/image/${page.slug}`}
      />
      <meta property='og:image:type' content='image/jpg' />
      <meta property='fb:app_id' content={process.env.FB_APP_ID} />
    */}
    </Helmet>
  );
};

export default PageMeta;
