import { useState, useEffect } from 'react';

export const useFetch = (data) => {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState('');

  useEffect(() => {
    setResults(data);
    setIsLoading(false);
  }, [data]);

  return { isLoading, results };
};
