const service = {
  title:
    'Coast to Coast Healthcare Solution provides high-quality care for all.',
  description:
    'We at Coast to Coast provides and assistance in day-to-day living to help clients stay comfortable at home. We believe that clients are more comfortable at home. We ensure that our professional team will provide the best care that will help our clients to achieve a better and happier life',
  service_list: [
    {
      id: 1,
      title: 'Homemaker',
      slug: 'homemaker',
      description:
        'A Home is a place that means a lot to people. In our homemaker services, we provide care for clients to continue to cherish their home. Our homemaker service ensures that clients are living in a clean environment.',
      list_title: 'Our Homemaker service offers:',
      lists_1: [
        'Clean Bedroom',
        'Clean Bathroom',
        'Clean Kitchen',
        'Dusting',
        'Mopping',
      ],
      lists_2: ['Sweeping', 'Vacuuming', 'Laundry', 'Empty Trash'],
      image: '../images/homemaker.jpg',
    },
    {
      id: 2,
      title: 'Personal Care',
      slug: 'personal-care',
      description:
        'We believe that as you age, you need a little help to carry out daily tasks. With our personal care service, we can assist you with everyday tasks. Our caregiver values your independence.',
      list_title: 'Our Personal Care service offers:',
      lists_1: [
        '	Bathing Assistance ',
        '	Dressing Assistance',
        '	Transfer Assistance',
        '	Assistance with feeding',
        ' Toileting Assistance',
        '	Hair Care/ Shampoo',
        '	Assistance With walking',
        '	Oral Hygiene',
      ],
      lists_2: [
        '	Transfer Assistance',
        '	Nail Care/Foot Care (Report any sores, redness, etc.)',
        '	Skin/Back Care (Report Breaks, Tears, etc.)',
        '	Range of motion Exercises',
        '	Mobility',
        '	Incontinence care',
      ],
      image: '../images/personal-care.jpg',
    },
    {
      id: 3,
      title: 'Respite Care',
      slug: 'respite-care',
      description:
        'Our Respite Care service provides caregivers that are devoted to accompanying or assist in the comfort of your home. Our caregivers will provide primarily emotional support and socialization to elate your day-to-day life. Our caregivers primarily focus on clients who enjoy living independently in their home environments. With us by your side, your loved ones will receive the best care with top-level caregivers.',
      list_title: 'Our Respite Care service offers:',
      list: [
        '	Emotional Support',
        '	Personal Care',
        '	Meal Preparation',
        '	Light House Cleaning',
        '	Emergency Shopping',
      ],
      image: '../images/respite-care.jpg',
    },
    {
      id: 4,
      title: 'Companion Sitter',
      slug: 'companion-sitter',
      description:
        'Many seniors live alone, which may leave them feeling lonely, isolated, or even depressed. Social isolation has shown to increase the risk of health complications and in some instance’s mortality among seniors. Companion care puts someone in the home to provide regular companionship for these seniors. Our companion care options are flexible and designed to meet your specific needs.  Whatever you are looking for, our caregivers are here to help you.',
      list_title: 'Our Companion service offers:',
      list: [
        '	Supervision/Monitor of activities of daily living',
        '	Accompany on walk',
        '	Assist with phone calls/communications',
        '	Conversation',
        '	Read',
        '	Escort to recreational activity',
        '	Accompany to appointment (medical/business)',
        '	Reminder for self-administered medication',
      ],
      image: '../images/companion-sitter.jpg',
    },
    {
      id: 5,
      title: 'Skilled Care Nursing',
      slug: 'skilled-care-nursing',
      description:
        'To meet the needs of clients, Coast to Coast offers nursing services performed by professional nurses. Our professional nursing focuses on the benefit of each client to ensure that they receive the high-quality care that they need. Our nursing staff provide one-on-one care to maintain or improve the well-being of our clients and also offer advanced care, working in conjunction with the family.',
      list_title: 'Our Nursing Care service offers:',
      list: [
        '	In home assessment',
        '	Diabetic Care ',
        '	Wound Care',
        '	Ostomy Care',
        '	Infusion Therapy',
        '	Aerosol Therapy',
        '	Orthopedic Care',
        '	Rehabilitative Care',
        '	Implementing Procedures in Home',
        '	Pain Management',
        '	Medication Management',
        '	Subcutaneous Injections',
        '	Intramuscular Injections',
        '	Emergency Management',
        '	Staff Relief',
      ],
      image: '../images/skilled-care-nurse.jpg',
    },
  ],
  image: [
    '../images/personal-care.jpg',
    '../images/smiling-senior-patient.jpg',
    '../images/respite-care.jpg',
  ],
};

export default service;
