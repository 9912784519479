const about = {
  title:
    'Fast & Reliable Home Health Care Solutions in Miami-Dade, Broward, and Palm Beach County.',
  desc:
    'Coast to Coast Healthcare Solutions offers customized senior healthcare and home health care services in South Florida, Miami-Dade, Broward, and Palm Beach county. In South Florida, we are known to give compassionate, personalized attention, and care to our clients.',

  sub_title: 'Coast to Coast Healthcare Solution offers',

  metaData: {
    title: 'Best Nurse Registry for senior healthcare',
    slug: 'seo.jpg',
    metaDesc:
      'Fast & Reliable Home Health Care Solutions in Miami-Dade, Broward, Palm Beach County.',
  },
  mission:
    'Coast to Coast Healthcare Solutions is committed to providing the BEST HEALTHCARE SERVICES and delivering SUPERIOR QUALITY CARE with highly trained and skilled professionals in a safe and secure environment.',
  providers: {
    title:
      'Our home healthcare providers are highly professional, straight forward, sincere, and dedicated.',
    text:
      'Many people in Monroe, Miami-Dade, Broward, and Palm Beach county entrust us with the task of taking care of the elderly members as well as ailing members of their families. They know from us, their loved ones will get the same amount of care and attention which they used to give. Our service team has the expertise to make senior living the most comfortable.',
  },
  image: [
    '../images/personal-care.jpg',
    '../images/miami-cargivers.jpg',
    '../images/respite-care.jpg',
  ],
};

export default about;
