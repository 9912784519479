const providers = {
  title: 'Caregivers provided by Coast to Coast Healthcare Solutions',
  description:
    'Our friendly and experienced caregivers provide a wide range of services and duties, designed to improve quality of life seniors, which could increase the longevity of independence for your loved one.',
  caregivers_provided: {
    title: 'Our Providers',
    list: [
      'Registered Nurse (RN)',
      'Licensed Practical Nurses (LPN)',
      'Certified Nursing Assistants (CNA)',
      'Home Health Aides (HHA)',
      'Companion Sitters',
      'Homemakers',
    ],
  },
  managers: [
    {
      name: 'Judy Jackson, ARNP, MSN',
      position: 'President / CEO',
    },
    {
      name: 'Andrea Lindo',
      position: 'Vice President / Administrator',
    },
  ],
};

export default providers;
