import React from 'react';
import { Link } from 'react-router-dom';
import { useFetch } from '../customHooks';
import PageHeader from '../component/PageHeader';
import data from '../models/service';

const Services = () => {
  const { isLoading, results } = useFetch(data);
  const { title, description, image, service_list } = results;

  const renderServices = service_list?.map((service, i) => (
    <div className='card' key={i}>
      <Link to={`/services/${service.slug}`} >
        <img
          src={service.image}
          alt={service.title}
          className='service-image'
        />
        <div className='card-content'>
          <h3 className='title-3'>{service.title}</h3>
          <p>{service.description.slice(0, 90).concat('...')}</p>
        </div>
      </Link>
    </div>
  ));

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <>
      <PageHeader
        title='Services'
        metaContent='We at Coast to Coast provides and assistance in day-to-day living to help clients stay comfortable at home'
      />

      <section className='services'>
        <div className='container'>
          <div className='services_content'>
            <div className='entry'>
              <h2>{title}</h2>
              <p>{description}</p>
            </div>
            <div className='img-container'>
              <img
                className='image-display-1'
                src={image[0]}
                alt='caregiver sitting with client while having tea'
              />
            </div>
          </div>
        </div>
      </section>

      <section className='services bg-light'>
        <div className='container'>
          <div className='services-header'>
            <div className='row'>
              <div className='col-9 d-center text-center'>
                <h4>Our Services</h4>
                <h1 className='title-1'>
                  We Strive for 100% Clients Satisfaction
                </h1>
              </div>
            </div>
          </div>
          <div className='card-row'>{renderServices}</div>
        </div>
      </section>
    </>
  );
};

export default Services;
