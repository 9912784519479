const slides = [
  {
    title: 'Our Approach',
    desc:
      ' At Coast to Coast Healthcare Solutions, we provide excellent healthcare services that leave your loved one feeling safe and comfortable at home.',
    url: '',
    image: '../images/caregiver-with-client.jpg',
  },
  {
    title: 'Our Standard',
    desc: '',
    url: '',
    image: '../images/staff_3.jpg',
  },
];

export default slides;
