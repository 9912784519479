import './SlideShow.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import data from '../../models/slides';

const SlideShow = () => {
  const [slides, setSlides] = useState(data);
  useEffect(() => {
    setSlides(data);
  }, []);

  return (
    <div className='slideshow'>
      <div className='container'>
        <div className='slideshow-content'>
          <h1 className='animate__animated animate__bounce'>
            {slides[0].title}
          </h1>
          <p>{slides[0].desc}</p>

          <Link to='/about' className='btn btn-blue btn btn-lg'>
            About Us
          </Link>
          <Link to='/services' className='btn btn-red btn btn-lg'>
            Our Services
          </Link>
        </div>
      </div>

      {
        <img
          src={slides[0].image}
          alt='Coast to Coast Healthcare Solutions caregiver with senior man'
          className='slide'
          width='1900px'
          height='600px'
        />
      }
    </div>
  );
};

export default SlideShow;
