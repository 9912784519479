import React, { useEffect, useState } from 'react';
import { MdKeyboardBackspace, FaCircle } from 'react-icons/all';
import { useParams, Link } from 'react-router-dom';
import { useFetch } from '../customHooks';
import PageHeader from '../component/PageHeader';
import data from '../models/service';

const Service = () => {
  const { isLoading, results } = useFetch(data);
  const [service, setService] = useState({});
  const { service_list } = results;
  const { slug } = useParams();

  useEffect(() => {
    const fetchData = () => {
      setService(service_list?.find((item) => item?.slug === slug));
    };
    fetchData();
  }, [slug, service_list]);

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <>
      <PageHeader
        title={`${service?.title}`}
        metaContent='We at Coast to Coast provides and assistance in day-to-day living to help clients stay comfortable at home'
      />
      <section className='ct-0'>
        <div className='container'>
          <div className='service_row'>
            <div className='service_row_left'>
              <div className='entry'>
                <h2>{service?.title}</h2>
                <p>{service?.description}</p>
              </div>
              <div className='service-lists'>
                <ul className='ct-list'>
                  {service?.lists_1?.map((list, i) => (
                    <li key={i}>
                      <FaCircle />
                      <span> {list}</span>
                    </li>
                  ))}
                </ul>

                <ul className='ct-list right'>
                  {service?.lists_2?.map((list, i) => (
                    <li key={i}>
                      <FaCircle />
                      <span> {list}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className='service_row_right'>
              <img
                src={service?.image}
                alt={service?.title}
                className='image-display-1'
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='back-to'>
            <MdKeyboardBackspace />
            <Link to='/services'>Back to Services</Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
